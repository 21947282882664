<template>
    <FormComponent class="register" @submit="login">
      <Check :loop="false" />
      <h3 class="auth-title">
        {{ $t('auth.email_verified') }}
      </h3>
      <p>{{ $t('auth.email_verified_text') }}</p>
      <div class="checkbox">
        <router-link class="yup-purple-color" to="/login">
          {{ $t('reset-password.to-login') }}
        </router-link>
      </div>
    </FormComponent>
  </template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import DarkMode from '@/assets/mixins/detectDark';
import FormComponent from '../../components/form/FormComponent.vue';
import Check from '../../components/rebranding/animLottie/Check.vue';

const webApi = axios();

export default {
  name: 'SuccessfullRegistration',
  beforeRouteEnter(to, from, next) {
    const emailVerify = localStorage.getItem('emailVerify');
    if (emailVerify) {
      localStorage.removeItem('emailVerify');
      next();
    } else {
      next('/login'); // Redirecione para a página de cadastro
    }
  },
  components: {
    FormComponent,
    Check,
  },
  mixins: [DarkMode],
  data() {
    return {
      show: false,
      token: '',
    };
  },
  computed: {
    isMainWhitelabel() {
      return (
        this.$store.state?.admin?.whitelabel?.['is_main'] ||
        !this.$store.state?.whitelabel
      );
    },
  },
  mounted() {
    this.verifyEmail();
  },
  methods: {
    back() {
      if (this.$router.currentRoute.path === '/login') {
        this.$parent.routing = false;
      } else {
        this.$router.back();
      }
    },
    verifyEmail() {
      this.token = this.$route.params.token;
      webApi.get(`/auth/verify-email/${this.token}`).then((response) => {
        if (response.data.success) {
          null;
        } else {
          null;
        }
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
  .social-group {
    gap: 16px;
  }
  .auth-title {
    font-size: 36px;
    margin-bottom: 26px;
    .btn {
      padding: 0;
      font-size: 24px;
    }
  }
  .checkbox {
    margin-bottom: 26px;
    font-weight: 500;
  }
  </style>
